/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';

/******************************* app styles ****************************/
@import './assets/styles/botones.scss';
@import './assets/styles/dropdown.scss';
@import './assets/styles/inputs.scss';
@import './assets/styles/checkboxes.scss';

@font-face {
    font-family: Rubik-Ligth;
    src: url('./assets/fonts/rubik/Rubik-Regular.ttf') format("opentype");
}

$mobileViewBreakPoint: /* 600 */850px;

.container {
    height: 100vh;
    background: black;
    color: white;
}

.app-root{
    display:block;
    height:100vh;
}

html, body {
    min-height: 100%;
    /* height: auto;
    margin: 0;
    background-color: rgb(247, 248, 249); */
}

body{
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    color : rgb(44,62,93);
    background-color: rgb(247, 248, 249);
}


* {
    font-family: Rubik-Ligth;
}





